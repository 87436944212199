<footer>
    <div class="w-full">
<!-- News Letter section start -->
<section class="news-letter-section bg-white pb-24">
    <div class="container">
        <div class="grid gap-x-4">
            <div class="col-span-12">
                <div class="section-title text-center pb-14">
                    <span class="font-medium text-orange text-base block mb-4">ESPACE INSCRIPTION</span>
                    <h3 class="font-playfair font-bold text-primary text-3xl md:text-4xl lg:text-xl">POUR LES DERNIÈRES NOUVELLES</h3>
                </div>
            </div>
            <div class="col-span-12">
                <div class="text-center">
                    <form id="mc-form" class="relative text-center md:max-w-3xl mx-auto">
                        <input id="mc-email" type="email" name="email" placeholder="email@example.com" class="border border-solid border-gray-600 w-full h-16 rounded-full bg-gray-400 placeholder-primary placeholder-opacity-50 text-base focus:outline-none py-1 pl-8 sm:pr-36" />
                        <button id="mc-submit" type="submit" class="bg-orange transition-all hover:bg-yellow-600 hover:text-white px-10 sm:px-5 py-5 sm:py-1 rounded-full text-white capitalize font-medium text-sm lg:text-md sm:absolute sm:top-0 sm:right-0 sm:h-full mt-3 sm:mt-0 leading-none w-full sm:w-auto">S'abonner</button>
                    </form>
                    <!-- mailchimp-alerts Start -->
                    <div class="mailchimp-alerts text-centre">
                        <div class="mailchimp-submitting"></div><!-- mailchimp-submitting end -->
                        <div class="mailchimp-success text-green-400"></div><!-- mailchimp-success end -->
                        <div class="mailchimp-error text-red-600"></div><!-- mailchimp-error end -->
                    </div>
                    <!-- mailchimp-alerts end -->
                </div>


            </div>
        </div>
    </div>
</section>
<!-- News Letter section end -->
<!-- footer start -->
<div class="bg-gray bg-no-repeat bg-center bg-cover pt-24" style="background-image:url('assets/images/footer/shape.webp')">
    <div class="container">

        <div class="grid lg:grid-cols-3 gap-4">
            <div class="">
              <h6 class="mb-4 flex space-x-3 justify-items-center font-semibold uppercase md:justify-start">
              <img class="rounded-lg h-8 w-8" src="/assets/img/logo.jpeg"  alt="" />
                <span class="text-primary font-bold text-md capitalize leading-none mb-8 font-playfair"> MacroInfos</span>
              </h6>
                <p class="mb-8">Nous travaillons avec les entreprises publiques, privées et les personnes tierces voulant automatiser leurs systèmes.</p>

                <div class="flex flex-wrap items-center justify-start">
                    <a href="#" aria-label="social links" class="text-lg text-dark hover:text-orange mr-3 leading-none transition"><i class="icon-social-facebook"></i></a>
                    <a href="#" aria-label="social links" class="text-lg text-dark hover:text-orange mx-3 leading-none transition"><i class="icon-social-twitter"></i></a>
                    <a href="#" aria-label="social links" class="text-lg text-dark hover:text-orange mx-3 leading-none transition"><i class="icon-social-instagram"></i></a>
                    <a href="#" aria-label="social links" class="text-lg text-dark hover:text-orange mx-3 leading-none transition"><i class="icon-social-youtube"></i></a>
                    <a href="#" aria-label="social links" class="text-lg text-dark hover:text-orange mx-3 leading-none transition"><i class="icon-social-dribbble"></i></a>
                </div>
            </div>

            
            <div class="">
                <h3 class="text-primary font-bold text-md capitalize leading-none mb-8 font-playfair">Liens utiles</h3>
                <ul>
                    <li class="ml-4 mb-5 list-circle"><a class="hover:text-orange transition-all" href="#">Magasin</a></li>
                    <li class="ml-4 mb-5 list-circle"><a class="hover:text-orange transition-all" href="#">Services &amp; Blog</a></li>
                    <li class="ml-4 mb-5 list-circle"><a class="hover:text-orange transition-all" href="#">Formation</a></li>
                    <li class="ml-4 mb-5 list-circle"><a class="hover:text-orange transition-all" href="#">Blogs</a></li>
                </ul>
            </div>

            <div class="">
              <h3 class="text-primary font-bold text-md capitalize leading-none mb-8 font-playfair">Autres services que nous rendons</h3>
              <ul>
                  <li class="ml-4 mb-5 list-circle"><a class="hover:text-orange transition-all" href="#">Installation de réseaux informatiques</a></li>
                  <li class="ml-4 mb-5 list-circle"><a class="hover:text-orange transition-all" href="#">Installation de caméra de surveillance</a></li>
                  <li class="ml-4 mb-5 list-circle"><a class="hover:text-orange transition-all" href="#">Installation de système d'alarm</a></li>
                  <li class="ml-4 mb-5 list-circle"><a class="hover:text-orange transition-all" href="#">Saisie et impression des document</a></li>
                  <li class="ml-4 mb-5 list-circle"><a class="hover:text-orange transition-all" href="#">Conception des logos et les affiches</a></li>
              </ul>
          </div>
        </div>


    </div>
</div>
<!-- footer end -->


<!-- Footer Bottom Section start -->
<div class="footer-bottom-section py-8 bg-gray-500">
    <div class="container">
        <div class="">
            <div class="flex order-last md:order-first flex-wrap items-center justify-center md:justify-start">
                <p class="text-white flex flex-wrap items-center text-sm lg:text-base">&copy; 2022 Sinp. Made with <i class="icon-heart mx-2 text-orange"></i> by<a href="#" class="ml-1 transition hover:text-orange">MacroInfos</a>.</p>
            </div>
        </div>

    </div>
</div>
<!-- Footer Bottom Section end -->
    </div>
      

  </footer>

  <!-- <a id="scrollUp" class="w-12 h-12 rounded-full bg-orange text-white fixed right-5 bottom-16 flex flex-wrap items-center justify-center transition-all duration-300 z-10" href="#"  aria-label="scroll up"><i class="icon-arrow-up"></i></a> -->
    <!-- Modals -->
