import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';




@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
  
  ],
  exports:[
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
   
  ],
  providers:[
    MatDatepickerModule,
    MatButtonModule
  ]
})
export class SharedModule { }
