import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path: 'site', loadChildren:()=>import('./site/site.module').then(m=>m.SiteModule)},
  {path: 'login', loadChildren:()=>import('./login/login.module').then(m=>m.LoginModule)},
  {path: 'inscription', loadChildren:()=>import('./inscription/inscription.module').then(m=>m.InscriptionModule)},
  {path: 'admin', loadChildren:()=>import('./admin/admin.module').then(m=>m.AdminModule)},
  {path: 'stock', loadChildren:()=>import('./boutique/boutique.module').then(m=>m.BoutiqueModule)},
  {path: 'rapport', loadChildren:()=>import('./rapport/rapport.module').then(m=>m.RapportModule)},
  {path:'devu', loadChildren:()=>import('./devu/devu.module').then(m=>m.DevuModule)},
  {path:'socomax', loadChildren:()=>import('./socomax/socomax.module').then(m=>m.SocomaxModule)},
  {path:'super',loadChildren:()=>import('./dashboard/dashboard.module').then(m=>m.DashboardModule)},
  {path: 'stocks', loadChildren:()=>import('./rapport-stock/rapport-stock.module').then(m=>m.RapportStockModule)},
  {path:'bon-commande', loadChildren:()=>import('./bon-commande/bon-commande.module').then(m=>m.BonCommandeModule)},

  {path: '**', redirectTo: 'site'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
