<!-- Blog section start -->
<section class="py-6 md:py-24">
    <div class="container">
        <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
                <div class="section-title text-center pb:6 md:pb-14">
                    <p class="font-medium text-orange  md:text-2xl block mb-4">FEATURED BLOGS</p>
                    <p class="font-playfair font-bold text-primary text-lg md:text-xl lg:text-xl">Latest Featured Blog</p>
                </div>
            </div>
            <div class="col-span-12">
                <section class="relative -m-4">
                    <div class=" p-4">
                        <div class="">
                            <div class="grid md:grid-cols-3 gap-4">
                                <div class="">
                                    <div class="border border-solid border-gray-300 p-[20px] md:p-[30px] group">
                                        <div class="mb-6">
                                            <a href="blog-details.html" class="overflow-hidden block">
                                                <img class="transform group-hover:scale-110 transition-transform duration-500 w-full h-full" src="assets/images/blog/blog10.webp" alt="blog image" loading="lazy" width="600" height="400" />
                                            </a>
                                        </div>
                                        <h3><a href="blog-details.html" class="block text-base md:text-md hover:text-orange transition-all font-medium mb-[10px]">Insde drone nihus mpkis</a></h3>
                                        <div class="blog-meta blog-mrg-border">
                                            <ul class="pb-[10px]">
                                                <li class="text-sm"><a href="#" class="text-sm text-dark hover:text-orange transition-all">21 April, 2021</a></li>
                                            </ul>
                                        </div>

                                        <p class="font-normal text-black text-sm mb-[25px]">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore...</p>
                                        <a class="bg-white transition-all hover:bg-orange hover:border-orange hover:text-white text-dark capitalize font-medium text-sm inline-block border border-solid border-gray-300 px-8 py-4 leading-none mb-[10px]" href="blog-details.html">blog details</a>
                                    </div>
                                </div>
                                <!--  end-->
                                <!--  start -->
                                <div class="">
                                    <div class="border border-solid border-gray-300 p-[20px] md:p-[30px] group">
                                        <div class="mb-6">
                                            <a href="blog-details.html" class="overflow-hidden block">
                                                <img class="transform group-hover:scale-110 transition-transform duration-500 w-full h-full" src="assets/images/blog/blog11.webp" alt="blog image" loading="lazy" width="600" height="400" />
                                            </a>
                                        </div>

                                        <h3><a href="blog-details.html" class="block text-base md:text-md hover:text-orange transition-all font-medium mb-[10px]">Drone nise mnise plkuh</a></h3>
                                        <div class="blog-meta blog-mrg-border">
                                            <ul class="pb-[10px]">
                                                <li class="text-sm"><a href="#" class="text-sm text-dark hover:text-orange transition-all">21 April, 2021</a></li>
                                            </ul>
                                        </div>

                                        <p class="font-normal text-black text-sm mb-[25px]">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore...</p>
                                        <a class="bg-white transition-all hover:bg-orange hover:border-orange hover:text-white text-dark capitalize font-medium text-sm inline-block border border-solid border-gray-300 px-8 py-4 leading-none mb-[10px]" href="blog-details.html">blog details</a>

                                    </div>
                                </div>
                                <!--  end-->
                                <!--  start -->
                                <div class="">
                                    <div class="border border-solid border-gray-300 p-[20px] md:p-[30px] group">
                                        <div class="mb-6">
                                            <a href="blog-details.html" class="overflow-hidden block">
                                                <img class="transform group-hover:scale-110 transition-transform duration-500 w-full h-full" src="assets/images/blog/blog12.webp" alt="blog image" loading="lazy" width="600" height="400" />
                                            </a>
                                        </div>

                                        <h3><a href="blog-details.html" class="block text-base md:text-md hover:text-orange transition-all font-medium mb-[10px]">Onisw dia vansi kanei</a></h3>
                                        <div class="blog-meta blog-mrg-border">
                                            <ul class="pb-[10px]">
                                                <li class="text-sm"><a href="#" class="text-sm text-dark hover:text-orange transition-all">21 April, 2021</a></li>
                                            </ul>
                                        </div>

                                        <p class="font-normal text-black text-sm mb-[25px]">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore...</p>
                                        <a class="bg-white transition-all hover:bg-orange hover:border-orange hover:text-white text-dark capitalize font-medium text-sm inline-block border border-solid border-gray-300 px-8 py-4 leading-none mb-[10px]" href="blog-details.html">blog details</a>
                                    </div>
                                </div>
                                <!-- swiper-slide end-->
                            </div>
                        </div>
                    </div>
                </section>




            </div>
        </div>
    </div>
</section>
<!-- Blog section end -->