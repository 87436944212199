<!-- <nav class="bg-teal-800" >
    <div class="container px-4 flex flex-wrap py-2 justify-between mx-auto lg:space-x-4" >
        <div>
          <a href="#" class=" inline-flex p-2 text-white text-2xl font-bold uppercase -tracking-wider">
            <span *ngIf="user.profil_id==1">MacroInfos</span> 
            <span *ngIf="user.profil_id!=1">{{user.denomination | uppercase}}</span></a>
          <button class="lg:hidden inline-flex items-center justify-center text-white border h-10 w-10 ml-auto rounded-md outline-none focus:outline-none" >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
          </button>
        </div>
        <div class="w-full lg:inline-flex lg:w-auto mt-2 lg:mt-0 py-2 space-x-4" >
          <div class="group inline-block">
            <button *ngIf="user.societe_id==1 && user.profil_id!=1" routerLink="/admin/dashboad" class="outline-none focus:outline-none  flex items-center min-w-32" >
              <span class="flex px-4 text-lg font-medium text-gray-300 hover:hover:text-white rounded-md py-2">DASHBOARD</span>
            </button>
            <button *ngIf="user.profil_id==1" routerLink="/super/super-admin" class="outline-none focus:outline-none  flex items-center min-w-32" >
              <span class="flex px-4 text-lg font-medium text-gray-300 hover:hover:text-white rounded-md py-2">DASHBOARD</span>
            </button>
            <button *ngIf="user.societe_id==3 && user.profil_id!=1" routerLink="/socomax/dashboard" class="outline-none focus:outline-none  flex items-center min-w-32" >
              <span class="flex px-4 text-lg font-medium text-gray-300 hover:hover:text-white rounded-md py-2">DASHBOARD</span>
            </button>
          </div>
            <div class="group inline-block">
                <button  class="outline-none focus:outline-none  flex items-center min-w-32" >
                  <span class="flex px-4 text-lg font-medium text-gray-300 hover:hover:text-white rounded-md py-2">IHM</span>
                </button>
                <ul class="bancgroud  transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top min-w-32 z-50" >
                  <li routerLink="/admin/magasin" *ngIf="droits.societe" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Sociétés </li>
                  <li routerLink="/admin/banque" *ngIf="droits.banque" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Banques</li>
                  <li routerLink="/admin/caisse_recette" *ngIf="droits.caisse_recette" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Caisses recettes</li>
                  <li routerLink="/admin/caisse-depense/list" *ngIf="droits.caisse_depense" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Caisses depenses</li>
                  <li routerLink="/admin/fournisseur" *ngIf="droits.fournisseur" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Fournisseurs</li>

                  <li routerLink="/bon-commande" *ngIf="droits.fournisseur" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Bon de commande</li>

                  <li routerLink="/admin/clients" *ngIf="droits.client && user.profil_id!=1" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Clients</li>

                  <li routerLink="/stock/articles" *ngIf="droits.article && user.profil_id!=1" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Articles</li>
                  <li routerLink="/stock/stocks" *ngIf="droits.stock && user.profil_id!=1" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Stock</li>

                  <li routerLink="/socomax/ventes" *ngIf="user.societe_id==3 && droits.ventes  && user.profil_id!=1" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Ventes</li>
                  <li routerLink="/stock/fiche-sortie" *ngIf="droits.fiche_stock_sortie && user.societe_id==1  && user.profil_id!=1" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Fiche de sotie du stock</li>
                  <li routerLink="/admin/proformas/add-proforma-sans-devu" *ngIf="droits.proforma_express && user.societe_id==1  && user.profil_id!=1" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures proformas avec dévu express</li>
                  <li routerLink="/admin/proformas/add-proforma-avec-devu" *ngIf="droits.proforma_avec_devu && user.societe_id==1  && user.profil_id!=1" routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures profoma avec dévu</li>
                  <li routerLink="/admin/proformas/list" *ngIf="droits.proforma && user.societe_id==1  && user.profil_id!=1" routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures profoma en attente de confirmation</li>
                  <li routerLink="/admin/proformas/proforma-confirmer" *ngIf="droits.proforma_confirme && user.societe_id==1  && user.profil_id!=1" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures proforma confirmer</li>
                  <li routerLink="/admin/proformas/proforma-attente-paiement" *ngIf="droits.proforma_attente_paiement && user.societe_id==1  && user.profil_id!=1" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black && user.societe_id==1">Factures proformas en attente de paiement</li>
                  <li routerLink="/socomax/invoices" *ngIf="droits.facturation && user.societe_id==3  && user.profil_id!=1" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures</li>
                  <li routerLink="/admin/factures/liste" *ngIf="droits.facturation && user.societe_id==1  && user.profil_id!=1" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures</li>
                  <li routerLink="/socomax/dettes-invoice" *ngIf="droits.facture_non_payer && user.societe_id==3" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures non payer</li>
                  <li routerLink="/admin/factures/dette" *ngIf="droits.facture_non_payer && user.societe_id==1  && user.profil_id!=1" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures non payer</li>
                  <li routerLink="/socomax/bon-commande" *ngIf="droits.bon_de_commande && user.societe_id==3  && user.profil_id!=1" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures confirmer par le bon de commande</li>
                  <li routerLink="/admin/bon-commande-list" *ngIf="droits.bon_de_commande  && user.societe_id==1  && user.profil_id!=1" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures confirmer par le bon de commande</li>
                  <li routerLink="/devu/liste" *ngIf="droits.rapport_proformat_confirmer  && user.societe_id==1  && user.profil_id!=1" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Liste des devus</li>
                </ul>
            </div>

            <div class="group inline-block">
                <button class="outline-none focus:outline-none  flex items-center min-w-32" >
                  <span class="flex px-4 text-lg font-medium text-gray-300 hover:hover:text-white rounded-md py-2">RAPPORTS</span>
                </button>
                <ul class="bancgroud  transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top min-w-32 z-50" >
                  <li routerLink="/rapport/proforma_enregistre" *ngIf="droits.rapport_proformat_enregistrer  && user.profil_id!=1 && user.societe_id==1" routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures profoma en attente de confirmation</li>
                  <li routerLink="/rapport/proforma_confirmer" *ngIf="droits.rapport_proformat_confirmer  && user.societe_id==1 && user.profil_id!=1" routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black ">Factures proforma confirmer</li>


                  <li routerLink="/rapport/facture_non_payees" *ngIf="droits.rapport_facture_non_payer  && user.profil_id!=1 && user.societe_id==1" routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures non payer</li>
                  <li routerLink="/rapport/facture_payer" *ngIf="droits.rapport_facture_payer  && user.profil_id!=1 && user.societe_id==1" routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures payer</li>

                  <li routerLink="/socomax/rapport/facture-non-payer" *ngIf="droits.rapport_facture_non_payer  && user.profil_id!=1 && user.societe_id==3" routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures non payer</li>
                  <li routerLink="/socomax/rapport/facture-payer" *ngIf="droits.rapport_facture_payer  && user.profil_id!=1 && user.societe_id==3" routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Factures payer</li>



                  <li routerLink="/rapport/banques" *ngIf="droits.rapport_banque" routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Banques</li>
                  <li routerLink="/rapport/caisse_recette" *ngIf="droits.rapport_caisse_recette" routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Caisses recettes</li>
                  <li routerLink="/rapport/caisse_depense" *ngIf="droits.rapport_caisse_depense" routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Caisses dépenses</li>
                  <li routerLink="/rapport/fournisseur" *ngIf="droits.rapport_fournisseur" routerLinkActive="active" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Fournisseurs</li>
                  <li routerLink="/stocks/rapport"  routerLinkActive="active" *ngIf="droits.rappot_stock"   class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Rapport des stocks</li>
                </ul>
            </div>
            <div class="group inline-block">
              <button class="outline-none focus:outline-none  flex items-center min-w-32" >
                <span class="flex px-4 text-lg font-medium text-gray-300 hover:hover:text-white rounded-md py-2">ADMINISTRATION</span>
              </button>
              <ul class="bancgroud  transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top min-w-32 z-50" >
                <li routerLink="/admin/profil" *ngIf="droits.profil" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Profils et droits </li>
                <li routerLink="/admin/password" routerLinkActive="active" *ngIf="droits.mot_de_passe" class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Modifier le mot de passe</li>
                <li routerLink="/admin/user" *ngIf="droits.user" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Utilisateurs</li>
              </ul>
            </div>
            <div class="group inline-block">
              <button  class="outline-none focus:outline-none  flex items-center min-w-32" >
                <span class="flex px-4 text-lg font-medium text-gray-300 hover:hover:text-white rounded-md py-2">SITE WEB</span>
              </button>
              <ul class="bancgroud  transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top min-w-32 z-50" >
                <li routerLink="/admin/magasin" *ngIf="droits.societe" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Hero </li>
                <li routerLink="/admin/banque" *ngIf="droits.banque" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">A propos</li>
                <li routerLink="/admin/caisse_recette" *ngIf="droits.caisse_recette" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Services</li>
                <li routerLink="/admin/caisse-depense/list" *ngIf="droits.caisse_depense" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Domaines</li>
                <li routerLink="/admin/fournisseur" *ngIf="droits.fournisseur" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Formations</li>
                <li routerLink="/bon-commande" *ngIf="droits.fournisseur" routerLinkActive="active"  class="px-3 py-2 bg-blue-900 text-white  text-lg font-extralight hover:bg-gray-200 hover:text-black">Equipes</li>
              </ul>
            </div>
        </div>
        <div class="w-full lg:inline-flex lg:w-auto mt-2 lg:mt-0 py-2 space-x-4">
          <button  (click)="confirmBox()" class="outline-none focus:outline-none  flex items-center min-w-32 space-x-2 "  >
             <span class="text-white uppercase">{{user.name}} {{user.postnom}}</span><img src="{{base_url}}{{user.image}}" alt="" class="h-8 w-8 rounded-full">
          </button>
        </div>
    </div>
</nav> -->



<header id="sticky-header" class="bg-teal-800">
  <div class="px-4 md:px-10 2xl:px-24 py-6 lg:py-0">
      <div class="flex items-center justify-between lg:relative">
          <div class="">
            <h1 class="font-playfair font-bold text-white text-3xl sm:text-4xl lg:text-5xl xl:text-2xl mb-5 inline-block md:block">MACROINFOS</h1>
          </div>
          <div class="hidden md:flex">
              <nav class="main-menu">
                  <ul class="flex flex-wrap">
                    <li class="main-menu__item"><a class="block py-10 xl:px-6 md:px-5 capitalize font-normal text-md text-white hover:text-orange transition-all" routerLink="/admin/dashboad">Dashboard</a></li>

                      <li class="main-menu__item relative group">
                          <a class="block py-10 xl:pr-6 md:pr-5 capitalize font-normal text-md text-white hover:text-orange transition-all">IHM</a>
                          <ul class="submenu bg-white py-3 px-8 shadow transition-all absolute left-0 top-full opacity-0 group-hover:opacity-100 invisible group-hover:visible group-hover:-translate-y-3 transform z-10 min-w-max">
                              <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" routerLink="/stock/articles" routerLinkActive="active" >Articles</a></li>
                              <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" routerLink="/stock/stocks" routerLinkActive="active">Stock</a></li>
                              <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" routerLink="/socomax/ventes" routerLinkActive="active">Ventes</a></li>

                              <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" routerLink="/admin/magasin" routerLinkActive="active" >Sociétés</a></li>
                              <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" routerLink="/admin/banque" routerLinkActive="active">Banques</a></li>
                              <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" routerLink="/admin/caisse_recette" routerLinkActive="active">Caisses recettes</a></li>
                              <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" routerLink="/admin/caisse-depense/list" routerLinkActive="active">Caisses recettes</a></li>
                          </ul>
                      </li>
                      <li class="main-menu__item relative group">
                        <a class="block py-10 xl:pr-6 md:pr-5 capitalize font-normal text-md text-white hover:text-orange transition-all">RAPPORTS</a>
                        <ul class="submenu bg-white py-3 px-8 shadow transition-all absolute left-0 top-full opacity-0 group-hover:opacity-100 invisible group-hover:visible group-hover:-translate-y-3 transform z-10 min-w-max">
                            <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange">Qui somme nous</a></li>
                            <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Notre equipe</a></li>
                            <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Notre mission</a></li>
                        </ul>
                    </li>
                    <li class="main-menu__item relative group">
                      <a class="block py-10 xl:pr-6 md:pr-5 capitalize font-normal text-md text-white hover:text-orange transition-all">ADMINISTRATION</a>
                      <ul class="submenu bg-white py-3 px-8 shadow transition-all absolute left-0 top-full opacity-0 group-hover:opacity-100 invisible group-hover:visible group-hover:-translate-y-3 transform z-10 min-w-max">
                          <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange">Qui somme nous</a></li>
                          <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Notre equipe</a></li>
                          <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Notre mission</a></li>
                      </ul>
                    </li>
                    <li class="main-menu__item relative group">
                      <a class="block py-10 xl:pr-6 md:pr-5 capitalize font-normal text-md text-white hover:text-orange transition-all">SITE WEB</a>
                      <ul class="submenu bg-white py-3 px-8 shadow transition-all absolute left-0 top-full opacity-0 group-hover:opacity-100 invisible group-hover:visible group-hover:-translate-y-3 transform z-10 min-w-max">
                          <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange">Qui somme nous</a></li>
                          <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Notre equipe</a></li>
                          <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Notre mission</a></li>
                      </ul>
                    </li>
                  </ul>
              </nav>
          </div>
          <div class="hidden md:flex">
            <nav class="main-menu">
                <ul class="flex flex-wrap">
                  <li class="main-menu__item relative group">
                    <a class="block py-10 xl:pr-6 md:pr-5 capitalize font-normal text-md text-white hover:text-orange transition-all lg:flex space-x-2"><img src="{{base_url}}{{user.image}}" alt="" class="h-8 w-8 rounded-full"><span class="text-white uppercase">{{user.name}} {{user.postnom}}</span></a>
                    <ul class="submenu bg-white py-3 px-8 shadow transition-all absolute left-0 top-full opacity-0 group-hover:opacity-100 invisible group-hover:visible group-hover:-translate-y-3 transform z-10 min-w-max">
                        <li class="my-3"><a class="text-rose-600 cursor-pointer font-normal text-base capitalize transition-all hover:text-orange" (click)="confirmBox()">Déconnexion</a></li>
                        <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Profil</a></li>
                    </ul>
                  </li>
                </ul>
            </nav>
        </div>

          <div class="md:hidden"  *ngIf="!smallMenu">
            <li class="ml-6">
              <a (click)="showMenu()" class="text-white text-md hover:text-orange transition-all"><i class="icon-menu"></i></a>
            </li>
          </div>
          <button (click)="showMenu()" *ngIf="smallMenu"  class="middle none relative ml-auto h-6 max-h-[40px] w-6 max-w-[40px] rounded-lg text-center font-sans text-xs font-medium uppercase hover:text-pink-500 text-blue-gray-500 transition-all hover:bg-transparent focus:bg-transparent active:bg-transparent disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none lg:hidden"
          >
          <span class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transform text-lg px-2 bg-rose-700 text-white rounded-full">
            x
          </span>
        </button>
      </div>
  </div>
</header>

 <div class="md:hidden" *ngIf="smallMenu">
     <div class="px-8 py-2">
         <nav class="offcanvas-men">
          <ul class="">
            <li class="main-menu__item"><a class="block py-0 xl:px-6 md:px-5 capitalize font-normal text-md text-primary hover:text-orange transition-all">Accueil</a></li>
              <li class="main-menu__item relative group">
                  <a class="block py-0 xl:pr-6 md:pr-5 capitalize font-normal text-md text-primary hover:text-orange transition-all">A propos </a>
                  <ul class="submenu bg-white py-3 px-8 shadow transition-all absolute left-0 top-full opacity-0 group-hover:opacity-100 invisible group-hover:visible group-hover:-translate-y-3 transform z-10 min-w-max">
                      <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange">Qui somme nous</a></li>
                      <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Notre equipe</a></li>
                      <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Notre mission</a></li>
                  </ul>
              </li>

              <li class="main-menu__item"><a class="block py-0 xl:px-6 md:px-5 capitalize font-normal text-md text-primary hover:text-orange transition-all">Magasin</a></li>

              <li class="main-menu__item relative group">
                <a class="block py-0 xl:pr-6 md:pr-5 capitalize font-normal text-md text-primary hover:text-orange transition-all">Services </a>
                <ul class="submenu bg-white py-3 px-8 shadow transition-all absolute left-0 top-full opacity-0 group-hover:opacity-100 invisible group-hover:visible group-hover:-translate-y-3 transform z-10 min-w-max">
                    <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange">Réseaux informatiques</a></li>
                    <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Caméra de surveillance</a></li>
                    <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >système d'alarm</a></li>
                    <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Panneau solaire</a></li>
                    <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Application et site web</a></li>
                    <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Application mobile</a></li>
                    <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Application desktop</a></li>
                    <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Conception de logo</a></li>
                    <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Conception des affiche</a></li>
                    <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >conception de dépliant</a></li>
                    <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Saisie et impression</a></li>
                </ul>
            </li>
              <li class="main-menu__item relative group"><a class="block py-0 xl:px-6 md:px-5 capitalize font-normal text-md text-primary hover:text-orange transition-all" href="#">Formations</a>

                  <ul class="submenu bg-white py-3 px-8 shadow transition-all absolute left-0 top-full opacity-0 group-hover:opacity-100 invisible group-hover:visible group-hover:-translate-y-3 transform z-10 min-w-max">
                      <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Bureautique</a></li>
                      <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Réseaux informatiques</a></li>
                      <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Programmation web</a></li>
                      <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Base des données</a></li>
                  </ul>

              </li>
              
              <li class="main-menu__item"><a class="block py-0 xl:px-6 md:px-5 capitalize font-normal text-md text-primary hover:text-orange transition-all">Contact</a></li>
          </ul>
         </nav>
     </div>
 </div>


















