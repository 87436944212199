<div class="h-28 hidden md:flex">
    <header  class="w-full bg-teal-800 fixed z-50">
        <div class="px-4 md:px-10 2xl:px-24 py-4 lg:py-0">
            <div class="flex items-center justify-between lg:relative">
                <div class="">
                  <h1 class="font-playfair font-bold text-white text-3xl sm:text-4xl lg:text-5xl xl:text-2xl mb-5 inline-block md:block">MACROINFOS</h1>
                </div>
                <div class="hidden md:flex">
                    <nav class="main-menu">
                        <ul class="flex flex-wrap">
                          <li class="main-menu__item"><a class="block py-10 xl:px-6 md:px-5 capitalize font-normal text-md text-white hover:text-orange transition-all" routerLink="/site">Accueil</a></li>
      
                            <li class="main-menu__item relative group">
                                <a class="block py-10 xl:pr-6 md:pr-5 capitalize font-normal text-md text-white hover:text-orange transition-all">Apropos</a>
                                <ul class="submenu bg-white py-3 px-8 shadow transition-all absolute left-0 top-full opacity-0 group-hover:opacity-100 invisible group-hover:visible group-hover:-translate-y-3 transform z-10 min-w-max">
                                    <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" routerLink="/site/appropos" routerLinkActive="active" >Qui somme nous</a></li>
                                    <li class="my-3"><a routerLink="/site/equipe" routerLinkActive="active" class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Notre equipe</a></li>
                                </ul>
                            </li>
                            <li class="main-menu__item"><a class="block py-10 xl:px-6 md:px-5 capitalize font-normal text-md text-white hover:text-orange transition-all" routerLink="/site/magasin" routerLinkActive="active" >Magasin</a></li>
      
                            <li class="main-menu__item group">
                                <a class="block py-10 xl:px-6 md:px-5 capitalize font-normal text-md text-white hover:text-orange transition-all" routerLink="/site/services" routerLinkActive="active">Services</a>
                                <ul class="mega-menu flex flex-wrap bg-white py-5 px-8 shadow transition-all absolute left-0 top-full opacity-0 group-hover:opacity-100 invisible group-hover:visible group-hover:-translate-y-3 transform z-10">
                                    <li class="flex-auto px-4">
                                        <a class="font-normal text-base capitalize text-dark pb-5 border-b block border-solid border-gray-600 mb-6 tracking-wide transition-all hover:text-orange" href="#">Installation des système. </a>
                                        <ul class="pb-2">
                                            <li class="my-3"><a class="font-normal text-base capitalize text-dark tracking-wide block hover:text-orange transition-all" routerLink="/site/services/reseaux-informatique" routerLinkActive="active" >Réseaux informatiques</a></li>
                                            <li class="my-3"><a class="font-normal text-base capitalize text-dark tracking-wide block hover:text-orange transition-all" routerLink="/site/services/installation-camera" routerLinkActive="active" >Caméra de surveillance</a></li>
                                            <li class="my-3"><a class="font-normal text-base capitalize text-dark tracking-wide block hover:text-orange transition-all" >système d'alarm</a></li>
                                            <li class="my-3"><a class="font-normal text-base capitalize text-dark tracking-wide block hover:text-orange transition-all" >Panneau solaire</a></li>
                                        </ul>
                                    </li>
      
                                    <li class="flex-auto px-4">
                                        <a class="font-normal text-base capitalize text-dark pb-5 border-b block border-solid border-gray-600 mb-6 tracking-wide transition-all hover:text-orange" >Conception et réalisation des application</a>
                                        <ul class="pb-2">
                                            <li class="my-3"><a class="font-normal text-base capitalize text-dark tracking-wide block hover:text-orange transition-all" >Application et site web</a></li>
                                            <li class="my-3"><a class="font-normal text-base capitalize text-dark tracking-wide block hover:text-orange transition-all" >Application mobile</a></li>
                                            <li class="my-3"><a class="font-normal text-base capitalize text-dark tracking-wide block hover:text-orange transition-all" >Application desktop</a></li>
                                        </ul>
                                    </li>
      
                                    <li class="flex-auto px-4">
                                        <a class="font-normal text-base capitalize text-dark pb-5 border-b block border-solid border-gray-600 mb-6 tracking-wide transition-all hover:text-orange" href="#">Graphisme</a>
                                        <ul class="pb-2">
                                            <li class="my-3"><a class="font-normal text-base capitalize text-dark tracking-wide block hover:text-orange transition-all" >Conception de logo</a></li>
                                            <li class="my-3"><a class="font-normal text-base capitalize text-dark tracking-wide block hover:text-orange transition-all" >Conception des affiche</a></li>
                                            <li class="my-3"><a class="font-normal text-base capitalize text-dark tracking-wide block hover:text-orange transition-all" >conception de dépliant</a></li>
                                            <li class="my-3"><a class="font-normal text-base capitalize text-dark tracking-wide block hover:text-orange transition-all" >Saisie et impression</a></li>
                                        </ul>
                                    </li>
                                    <li class="overflow-hidden flex-auto mx-4"><a href="#"><img class="transform hover:scale-105 transition-all w-full" src="assets/images/mega-menu/megamenu.webp" alt="Smartwatch" loading="lazy" width="1000" height="120" /></a></li>
                                </ul>
                            </li>
                           
                            <li class="main-menu__item"><a class="block py-10 xl:px-6 md:px-5 capitalize font-normal text-md text-white hover:text-orange transition-all" routerLink="/site/formation" routerLinkActive="active" >Formations</a></li>
                            <li class="main-menu__item"><a class="block py-10 xl:px-6 md:px-5 capitalize font-normal text-md text-white hover:text-orange transition-all"  routerLink="/site/contact" routerLinkActive="active">Contact</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </header>
</div>

<div class="h-16 md:hidden">
    <header  class="w-full bg-teal-800 fixed z-50">
        <div class="px-4 md:px-10 2xl:px-24 py-4 lg:py-0">
            <div class="flex items-center justify-between lg:relative">
                <div class="">
                  <h1 class="font-playfair font-bold text-white text-3xl sm:text-4xl lg:text-5xl xl:text-2xl mb-5 inline-block md:block">MACROINFOS</h1>
                </div>
                <div class="md:hidden"  *ngIf="!smallMenu">
                  <li class="ml-6">
                    <a (click)="showMenu()" class="text-white text-md hover:text-orange transition-all"><i class="icon-menu"></i></a>
                  </li>
                </div>
                <button (click)="showMenu()" *ngIf="smallMenu"  class="middle none relative ml-auto h-6 max-h-[40px] w-6 max-w-[40px] rounded-lg text-center font-sans text-xs font-medium uppercase hover:text-pink-500 text-blue-gray-500 transition-all hover:bg-transparent focus:bg-transparent active:bg-transparent disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none lg:hidden"
                >
                <span class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transform text-lg px-2 bg-rose-700 text-white rounded-full">
                  x
                </span>
              </button>
            </div>
        </div>
    </header>
</div>


 <div class="md:hidden fixed bg-white z-50 w-full" *ngIf="smallMenu">
     <div class="px-8 pt-4">
         <nav class="offcanvas-men">
          <ul class="">
            <li class="main-menu__item"><a class="block py-0 xl:px-6 md:px-5 capitalize font-normal text-md text-primary hover:text-orange transition-all" routerLink="/site" >Accueil</a></li>
              <li class="main-menu__item relative group">
                  <a class="block py-0 xl:pr-6 md:pr-5 capitalize font-normal text-md text-primary hover:text-orange transition-all" >A propos </a>
                  <ul class="submenu bg-white py-3 px-8 shadow transition-all absolute left-0 top-full opacity-0 group-hover:opacity-100 invisible group-hover:visible group-hover:-translate-y-3 transform z-10 min-w-max">
                      <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" routerLink="/site/appropos" routerLinkActive="active" >Qui somme nous</a></li>
                      <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" routerLink="/site/equipe" routerLinkActive="active">Notre equipe</a></li>
                      <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" routerLink="/site/equipe" routerLinkActive="active">Notre mission</a></li>
                  </ul>
              </li>

              <li class="main-menu__item"><a class="block py-0 xl:px-6 md:px-5 capitalize font-normal text-md text-primary hover:text-orange transition-all" routerLink="/site/magasin" routerLinkActive="active">Magasin</a></li>

              <li class="main-menu__item relative group">
                <a class="block py-0 xl:pr-6 md:pr-5 capitalize font-normal text-md text-primary hover:text-orange transition-all" routerLink="/site/services" routerLinkActive="active">Services </a>
                <ul class="submenu bg-white py-3 px-8 shadow transition-all absolute left-0 top-full opacity-0 group-hover:opacity-100 invisible group-hover:visible group-hover:-translate-y-3 transform z-10 min-w-max">
                    <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" routerLink="/site/services/reseaux-informatique" routerLinkActive="active">Réseaux informatiques</a></li>
                    <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" routerLink="/site/services/installation-camera" routerLinkActive="active" >Caméra de surveillance</a></li>
                    <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >système d'alarm</a></li>
                    <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Panneau solaire</a></li>
                    <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Application et site web</a></li>
                    <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Application mobile</a></li>
                    <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Application desktop</a></li>
                    <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Conception de logo</a></li>
                    <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Conception des affiche</a></li>
                    <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >conception de dépliant</a></li>
                    <li class="my-3"><a class="text-dark font-normal text-base capitalize transition-all hover:text-orange" >Saisie et impression</a></li>
                </ul>
            </li>
              <li class="main-menu__item"><a class="block py-0 xl:px-6 md:px-5 capitalize font-normal text-md text-primary hover:text-orange transition-all" routerLink="/site/formation" routerLinkActive="active">Contact</a></li>
              
              <li class="main-menu__item"><a class="block py-0 xl:px-6 md:px-5 capitalize font-normal text-md text-primary hover:text-orange transition-all" routerLink="/site/contact" routerLinkActive="active">Contact</a></li>
          </ul>
         </nav>
     </div>
 </div>

 














